import React from "react";
import {Image, Container, Row, Col } from "react-bootstrap";
import Line from "./Line";
import creditper from "../images/splashscreen.png";


const MissionVission = ({getTranslatedText}) => {
  return (
    <section id="about" className="about">
      <Container>
      <Row>
          <Col className="about__col-left" md={12} lg={6} xl={6}>
            <Image src={creditper} fluid={true} className="about__image" />
          </Col>
          <Col className="about__col-right" md={12} lg={6} xl={6}>
          <Line type="large" />
            <h2 className="about__heading">{getTranslatedText("Vision Heading")}</h2>
            <p className="about__text">
            {getTranslatedText("Vision Descp")}
            </p>
            &nbsp;

            <Line type="large" />
            <h2 className="about__heading">{getTranslatedText("Mission Heading")}</h2>
            <p className="about__text">
            {getTranslatedText("Mission Descp")}
            </p>
            &nbsp;
           
            <Line type="large" />
            <h2 className="about__heading">{getTranslatedText("Statement Heading")}</h2>
            <p className="about__text">
            {getTranslatedText("Statement Descp")}
            </p>
          </Col>
        </Row>

      </Container>
    </section>
  );
};

export default MissionVission;
